import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import Module from '@components/pageModules';

import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';

const IndexPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;

  const { modules: pageModules, seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      {pageModules?.modules?.map((item) => (
        <Module key={item?.key} module={item} />
      ))}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      modules {
        ...PageModules
      }
    }
  }
`;
